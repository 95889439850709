import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import GLightbox from "glightbox"

export default class extends Controller {
  connect() {
  }

  accept() {
    // Hide modal
    Modal.getInstance(this.element).hide();
    // Open Google Maps lightbox
    const lightbox = GLightbox({ elements: [{ 'href': this.element.dataset.mapsurl, 'type': 'external' }] });
    lightbox.open();
  }
}
