import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="substitute"
export default class extends Controller {
  static targets = [ "allInstructorsSelect", "courseInstructorsSelect", "dateInput", "hiddenInput", "substitutionSelect" ]
  static values = {
    allInstructors: Array,
    courseInstructors: Array,
  }
  instructorsMap = new Map()

  connect() {
    this.allInstructorsValue.forEach((el) => {
      const [id, name] = el
      this.instructorsMap.set(id, name)

      const option = document.createElement("option")
      option.value = id
      option.text = name
      this.allInstructorsSelectTarget.add(option)
    })
    this.courseInstructorsValue.forEach((el) => {
      const [id, name] = el
      const option = document.createElement("option")
      option.value = id
      option.text = name
      this.courseInstructorsSelectTarget.add(option)
    })
    if (this.hiddenInputTarget.value != "") {
      const currentSubstitutes = JSON.parse(this.hiddenInputTarget.value)
      if (currentSubstitutes !== null) {
        currentSubstitutes.forEach((substitute) => {
          this.substitutionSelectTarget.add(
            this.newSubOption(substitute.date, substitute.instructorId, substitute.substituteId)
          )
        })
      }
    }
  }

  addSubstitution(event) {
    event.preventDefault()

    if (this.dateInputTarget.value == '') {
      alert(`Es muss ein Datum ausgewählt werden!`)
      return
    }
    if (this.courseInstructorsSelectTarget.selectedIndex < 1 && this.allInstructorsSelectTarget.selectedIndex < 1) {
      alert(`Es muss zumindest ein Ausfall oder eine Vertretung ausgewählt werden!`)
      return
    }

    const date = this.dateInputTarget.value

    const courseInstructorOption = this.courseInstructorsSelectTarget.options[this.courseInstructorsSelectTarget.selectedIndex]
    const instructorId = parseInt(courseInstructorOption.value)
    const substituteInstructorOption = this.allInstructorsSelectTarget.options[this.allInstructorsSelectTarget.selectedIndex]
    const substituteId = substituteInstructorOption.value == '' ? null : parseInt(substituteInstructorOption.value)

    if (instructorId === substituteId) {
      alert(`Derselbe Kursleiter als Ausfall und Vertretung ist nicht erlaubt!`)
      return
    }

    const subOption = this.newSubOption(date, instructorId, substituteId)
    if (subOption === null) {
      console.log('Invalid subOption!')
      return
    }
    this.substitutionSelectTarget.add(subOption)
    this.updateHiddenInput()
  }

  deleteSubstitution(event) {
    event.preventDefault()
    if (this.substitutionSelectTarget.selectedIndex !== -1) {
      this.substitutionSelectTarget.remove(this.substitutionSelectTarget.selectedIndex)
      this.updateHiddenInput()
    }
  }

  newSubOption(date, instructorId, substituteId) {
    const dateObject = new Date(date)
    const subOption = document.createElement("option");
    subOption.value = JSON.stringify({
      "date": dateObject,
      "instructorId": instructorId,
      "substituteId": substituteId,
    })

    const localDate = dateObject.toLocaleDateString()
    if (!instructorId && !substituteId) {
      return null
    } else if (instructorId && substituteId) {
      subOption.text = `Vertretung von ${this.instructorsMap.get(instructorId)} durch ${this.instructorsMap.get(substituteId)} am ${localDate}`
    } else if (!instructorId) {
      subOption.text = `Zusatztrainer ${this.instructorsMap.get(substituteId)} am ${localDate}`
    } else {
      subOption.text = `Ausfall von ${this.instructorsMap.get(instructorId)} am ${localDate}`
    }
    return subOption
  }

  updateHiddenInput() {
    const substitutions = [...this.substitutionSelectTarget.options].map((option) => {
      return JSON.parse(option.value)
    })
    this.hiddenInputTarget.value = JSON.stringify(substitutions)
  }
}
