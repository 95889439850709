import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "amount", "userAttends" ]
  static values = {
    coursePrice: Number,
    maxSeats: Number,
    pricePerBooking: Boolean,
    seasonPass: Boolean,
    userTooOld: Boolean,
    waitingList: Boolean,
  }
  numFamilyMembers = 0

  connect() {
    this.clearAmount()
  }

  clearAmount() {
    this.amountTarget.innerHTML = "€ 0,00"
  }

  formatAmount(amount) {
    return `€ ${amount.toFixed(2).toString().replace(".", ",")}`
  }

  handleCheckboxUpdate(event) {
    // Validate if the user is too old for this course
    if (this.userTooOldValue) {
      event.preventDefault()
      this.userAttendsTarget.checked = false
      alert('Erwachsene können nicht zu Kinderkursen angemeldet werden!')
      return
    }
    // Validate if there is still room for the user himself, except for waiting lists
    if (!this.waitingListValue && this.numFamilyMembers >= this.maxSeatsValue && this.userAttendsTarget.checked) {
      event.preventDefault()
      this.userAttendsTarget.checked = false
      alert('Es sind leider keine weiteren Plätze mehr verfügbar!')
      return
    }

    // Calculate maximum number of allowed family members and pass to nested-form-controller
    let maxFamilyMembers = this.maxSeatsValue
    if (this.userAttendsTarget.checked) {
      maxFamilyMembers--
    }
    this.dispatch('maxAssociationsUpdate', { target: document, detail: { maxAssociations: maxFamilyMembers } })
    // Update total amount
    this.updateAmount()
  }

  handleFamilyMembersUpdate(event) {
    this.numFamilyMembers = parseInt(event.detail.numAssociations)
    this.updateAmount()
  }

  updateAmount() {
    if (this.numFamilyMembers > 0 || this.userAttendsTarget.checked) {
      let amount = 0
      let amountHTML = ''
      if (this.pricePerBookingValue) {
        // Price per booking (free if user has a season pass)
        amount = this.seasonPassValue ? 0 : this.coursePriceValue
        amountHTML = this.formatAmount(amount)
        if (this.seasonPassValue) {
          amountHTML += ` (Saisonkarten-Preisreduktion aktiv)`
        }
      } else {
        // Price per attendee (only free for user as attendee himself)
        amount = this.numFamilyMembers * this.coursePriceValue
	if (this.userAttendsTarget.checked) {
          amount += this.seasonPassValue ? 0 : this.coursePriceValue
	}
        amountHTML = this.formatAmount(amount)
        if (this.seasonPassValue && this.userAttendsTarget.checked) {
          amountHTML += ` (Saisonkarten-Preisreduktion für Benutzer aktiv)`
        }
      }
      this.amountTarget.innerHTML = amountHTML
    }
    else {
      this.clearAmount()
    }
  }
}
